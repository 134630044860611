// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
// require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

var position = require('jquery-ui/ui/position');
var draggable = require('jquery-ui/ui/widgets/draggable');
var sortable = require('jquery-ui/ui/widgets/sortable');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap';
import 'jquery';
import 'huebee';
import 'select2';
import 'underscore';
import 'underscore.string';
import 'pluralize';
import 'moment';
import 'datatables.net';
import 'datatables.net-responsive';
import 'datatables.net-scroller';
import 'datatables.net-fixedcolumns';
import 'flatpickr';
import 'dropzone';
import 'chart.js';
import 'auth0-js';
import 'simplebar';

import 'src/cocoon';
import 'src/inobounce.min';
import 'src/jquery.geocomplete.min';
import 'src/jquery.initialize.min';

import 'dashkit/charts';
import 'dashkit/Chart.extension';
import 'dashkit/flatpickr';

import 'icons';
import 'misc';
import 'global';
import 'display_size';
import 'datatables';
import 'listview';
import 'modal';
import 'navbar';
import 'nested_fields';
import 'modal_wizard';

import 'alerts';
import 'audits';
import 'auth0';
// import 'userflow';
import 'charts';
import 'dietary_flags';
import 'equivalencies_input';
import 'ingredient_selector_input';
import 'helpers';
import 'inputs';
import 'inventory_items';
import 'invoices';
import 'locations';
import 'organizations';
import 'organization_users';
import 'reports';
import 'tabs';
import 'tooltip';
import 'users';
import 'units';
import 'validations';
import 'vendors';
import 'wizard';

// Break bfcache in Firefox and Safari
window.addEventListener('unload', function(){});
